import * as React from 'react';
import {useEffect} from 'react';
import  ContactForm from './contact/contact.form';

export interface IAppProps {
  title?:string;
}
export default function Contact (props: IAppProps) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
    <section id="contact" className="contact subpage">
      <div className="container">

        <div className="section-title">
          <h2>Contact</h2>
        </div>
      </div>

      <div>
        <iframe style={{border:0, width: "100%", height: "350px"}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3022.318243030529!2d-73.9938397!3d40.7550247!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259ace01f6307%3A0xd73d205381c3cb2!2s575%208th%20Ave%2C%20New%20York%2C%20NY%2010018%2C%20USA!5e0!3m2!1sen!2str!4v1638728505533!5m2!1sen!2str" ></iframe>
      </div>

      <div className="container">
        <div className="row mt-5">

          <div className="col-lg-4">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>{process.env.REACT_APP_ADDRESS}</p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>info@{process.env.REACT_APP_HOST}</p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>{process.env.REACT_APP_PHONE}</p>
              </div>

            </div>

          </div>

          <div className="col-lg-8 mt-5 mt-lg-0">
{/* 
            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows={5} placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form> */}
            <ContactForm/>

          </div>

        </div>

      </div>
      
    </section>
  
    </div>
  );
}
