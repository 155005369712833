import React from 'react';
import { Translate } from 'react-jhipster';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import appConfig from 'app/config/constants';

export const BrandIcon = props => (
  <div  {...props} className="brand-icon">
    <img height="58" src="content/images/logo-w.png" alt="Logo" />
  </div>
);

export const Brand = props => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <BrandIcon />
    <span style={{marginTop:"5px",fontSize:"28px",color:"#FFFFFF"}} className="brand-title">
      <Translate contentKey="global.title">{process.env.REACT_APP_COMPANY}</Translate>
    </span>
    {/* <span className="navbar-version">{appConfig.VERSION}</span> */}
  </NavbarBrand>
);

export const Home = props => (
  <NavItem>
    <NavLink onClick={()=>props.toggle(false)} tag={Link} to="/home" className="d-flex align-items-center">
      {/* <FontAwesomeIcon icon="home" /> */}
        <Translate contentKey="global.menu.home">Home</Translate>
    </NavLink>
  </NavItem>
);

export const SiteMenuItem = props => (
  <NavItem >
    <NavLink onClick={()=>props.toggle(false)}  tag={Link} to={props.path} className="d-flex align-items-center">
      {/* <FontAwesomeIcon icon="home" /> */}
        {props.name}
    </NavLink>
  </NavItem>
);
