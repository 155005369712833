import * as React from 'react';
import {useEffect} from 'react';
import Contact from './Contact';
import Footer from './Footer';
import Header from './Header';

interface IAppProps {
  title?:string
}

export default function Doctors (props: IAppProps) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
    <section id="doctors" className="doctors subpage">
    <div className="container">

      <div className="section-title">
        <h2>Doctors</h2>
        {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
      </div>

      <div className="row">

        <div className="col-lg-6">
          <div className="member d-flex align-items-start">
            <div className="pic"><img src="content/assets/img/doctors/doctors-1.jpg" className="img-fluid" alt=""/></div>
            <div className="member-info">
              <h4>Walter White</h4>
              <span>Chief Medical Officer</span>
              <div className="social">
                <a href=""><i className="ri-twitter-fill"></i></a>
                <a href=""><i className="ri-facebook-fill"></i></a>
                <a href=""><i className="ri-instagram-fill"></i></a>
                <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 mt-4 mt-lg-0">
          <div className="member d-flex align-items-start">
            <div className="pic"><img src="content/assets/img/doctors/doctors-2.jpg" className="img-fluid" alt=""/></div>
            <div className="member-info">
              <h4>Sarah Jhonson</h4>
              <span>Anesthesiologist</span>
              <div className="social">
                <a href=""><i className="ri-twitter-fill"></i></a>
                <a href=""><i className="ri-facebook-fill"></i></a>
                <a href=""><i className="ri-instagram-fill"></i></a>
                <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 mt-4">
          <div className="member d-flex align-items-start">
            <div className="pic"><img src="content/assets/img/doctors/doctors-3.jpg" className="img-fluid" alt=""/></div>
            <div className="member-info">
              <h4>William Anderson</h4>
              <span>Cardiology</span>
              <div className="social">
                <a href=""><i className="ri-twitter-fill"></i></a>
                <a href=""><i className="ri-facebook-fill"></i></a>
                <a href=""><i className="ri-instagram-fill"></i></a>
                <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 mt-4">
          <div className="member d-flex align-items-start">
            <div className="pic"><img src="content/assets/img/doctors/doctors-4.jpg" className="img-fluid" alt=""/></div>
            <div className="member-info">
              <h4>Amanda Jepson</h4>
              <span>Neurosurgeon</span>
              <div className="social">
                <a href=""><i className="ri-twitter-fill"></i></a>
                <a href=""><i className="ri-facebook-fill"></i></a>
                <a href=""><i className="ri-instagram-fill"></i></a>
                <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
  </div>
  );
}
